<template>
  <modal-wrapper :id="id" hide-footer size="md">
    <template #title>
      Il mio Qr Code
    </template>
    <template #body>
      <div>
        <b-img fluid :src="badgeUri" />
      </div>
      <b-input-group class="input--alt-style form-inputs--lg">
        <b-form-input type="text" disabled :value="serialCode"></b-form-input>
        <b-input-group-append class="ms-2">
          <b-button
            variant="warning"
            @click="openExternal"
          >
            <font-awesome-icon icon="external-link-alt"/>
          </b-button>
          <b-button
            variant="warning"
            v-clipboard="badgeUriToShare"
            @success="handleSuccess"
            @error="handleError"
          >
            <font-awesome-icon icon="copy"/>
          </b-button>
          <b-button
            variant="warning"
            @click="openModalShareBadge"
          >
            <font-awesome-icon icon="share-alt"/>
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <modal-badge-share
        id="badgeShareModal"
        :uri="badgeUriToShare"
        modalTitle="Invia richiesta feedback a"
        :title="shareTitle"
        :description="shareDescription"
      />
    </template>
  </modal-wrapper>
</template>

<script>
import { createBadgeCompleteUrl, createBadgeUri } from '../../utils/utilities';
import { toastInformation } from '../../utils/toast';

const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');
const ModalBadgeShare = () => import('@/components/userSections/ModalBadgeShare.vue');

export default {
  name: 'ModalBadgeQrCode',
  components: {
    ModalWrapper,
    ModalBadgeShare,
  },
  props: {
    id: String,
    serialCode: String,
  },
  computed: {
    currentName() {
      return this.$store.getters['auth/currentName'];
    },
    shareTitle() {
      return `${this.currentName} ti ha richiesto di inviare un feedback`;
    },
    shareDescription() {
      return `Questo è il link da usare per fornire un feedback su ${this.currentName}`;
    },
    badgeUri() {
      return createBadgeUri(this.serialCode);
    },
    badgeUriToShare() {
      return createBadgeCompleteUrl(this.serialCode);
    },
  },
  methods: {
    handleSuccess(e) {
      console.log('copy data', e);
      toastInformation('Link badge copiato nella clipboard');
    },
    handleError(e) {
      console.log('copy data error', e);
    },
    openExternal() {
      // console.log('external', this.badgeUriToShare);
      window.open(this.badgeUriToShare, '_blank');
    },
    openModalShareBadge() {
      console.log('trynna share');
      this.$bvModal.show('badgeShareModal');
    },
    reload() {
      console.log('qrbadge feedback reload');
      if (this.reloadData) this.reloadData();
    },
  },
};
</script>

<style scoped>

</style>
